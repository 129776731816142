<template>
    <div>
        <div>
            <bread-crumb :crumbData="['轮播图管理']" :chooseCrumbData="1"></bread-crumb>
            <div class="box">
                <div class="title">轮播图</div>
                <ul class="content">
                    <li v-for="item in  img_list " :key="item.id">
                        <div>
                            <img width="100px" height="100px" :src="'http://192.168.3.3/' + item.url" />
                        </div>
                        <el-button style="width:90px" type="danger" @click="remove(item.id)"> 删除</el-button>
                    </li>
                </ul>
                <div>
                    <form ref="f1" style="margin:10px 0px">
                        <el-row>
                            <input type="file" name="file" ref="file" accept=".png,.jpg,image/png,image/jpeg" />
                        </el-row>
                        <input name="link" hidden v-model="shop_id" />
                    </form>
                    <el-row style="margin:10px 0px">
                        <el-button @click="add">选择商品</el-button>
                        <span>商品ID:{{ shop_id
                        }}</span>
                    </el-row>
                    <el-row style="margin:10px 0px">
                        <el-button @click="submit">提交</el-button>
                    </el-row>
                </div>
            </div>
        </div>
        <el-dialog title="商品选择" :visible.sync="show" @close="handleClose" width="64%">
            <div style="padding: 0px; margin-top: 0px">
                <div class="file_form">
                    <el-col :span="4">
                        <List :classList="classList" @allFile="allFile" @labelClick="labelClick" />
                    </el-col>
                    <div style="padding: 10px 0; width: 100%">
                        <el-table ref="multipleTable" :border="true" :data="tableData" tooltip-effect="dark"
                            style="width: 100%; border: 1px solid #e5e5e5" @cell-dblclick="productClick">
                            <el-table-column type="selection" align="center"> </el-table-column>
                            <el-table-column prop="id" sortable label="编号" align="center">
                            </el-table-column>
                            <el-table-column prop="product_name" label="名称" align="center">
                            </el-table-column>
                            <el-table-column prop="unit" label="单位" align="center">
                            </el-table-column>
                            <el-table-column prop="market_price" sortable label="市场价" align="center">
                            </el-table-column>
                            <el-table-column prop="sales_price" sortable label="销售价" align="center">
                            </el-table-column>
                        </el-table>
                        <pagination :handleQuery="handleQuery" :currentPage="query.currentPage"
                            :currentLength="query.currentLength" :total="total">
                        </pagination>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue"
import List from "@/components/main/list";
export default {
    data() {
        return {
            total: 0,
            shop_id: null,
            commercial_id: localStorage.getItem("commercial_id") ||
                sessionStorage.getItem("commercial_id"),
            query: {
                currentPage: 1,
                currentLength: 6,
                commercial_id: localStorage.getItem("commercial_id") ||
                    sessionStorage.getItem("commercial_id")
            },
            img_list: [],
            show: false,
            classList: [],
            tableData: [],
            cid: 0,

        }
    },
    components: {
        List, pagination
    },
    async mounted() {
        this.img_list = (await this.$http.post("/system/Home_img/lst")).data;
        this.getClassList();
        this.handleQuery();
        // 获取当前轮播图的列表
    },
    computed: {

    },
    methods: {
        async remove(id) {
            let res = (await this.$http.post("/system/Home_img/del", { id })).data;
            if (res.status = "success") {
                this.$message.success("删除成功");
                this.img_list = this.img_list.filter(el => el.id != id)
            } else {
                this.$message("删除失败")
            }
        },
        async submit() {
            let f1 = new FormData(this.$refs.f1);
            let res = (await this.$http.post("/system/Home_img/img", f1)).data;
            if (res.status == "success") {
                this.shop_id = null;
                this.$refs.file.value = null;
                this.$message.success("添加成功");
                this.handleQuery()
            } else {
                this.$message("添加失败")
            }
        },
        add() {
            this.show = true
            console.log("点击了添加按钮")
        },
        // 点击添加轮播图的函数
        async handleQuery(page = 1) {
            let query = this.query;
            query.cid = this.cid;
            query.currentPage = page;

            let res = (await this.$http.post("/product/lst", query)).data;
            this.tableData = res.data;
            this.total = res.count
        },
        allFile() {
            if (this.cid == 0) return;
            this.cid = 0;
            this.handleQuery()
        },
        labelClick(id) {
            console.log('aaa');
            this.cid = id;
            this.handleQuery();
        },
        getClassList() {
            this.$http
                .post("/Goods_Category/lst", {
                    commercial_id: this.commercial_id,
                })
                .then((res) => {
                    this.classList = res.data.data;
                });
        },
        // 关闭弹窗回调
        handleClose() {
            this.show = false;
        },
        //获取商品列表
        productAdd() {
            listProduct(this.page, this.length, this.commercial_id, this.searchVal)
                .then((res) => {
                    this.tableData1 = res.data.data;
                    this.tableFinshNum = res.data.count;
                });
        },
        productClick(row) {
            let { id } = row;
            this.show = false
            this.shop_id = id
        },
    }
}
</script>
<style scoped lang="less">
.box {
    margin: 20px 30px;

    .title {
        font-size: 24px;
    }

    .content {
        margin: 10px 0px;
        display: flex;
    }
}
</style>